














































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsVerticalPercentageBar from '@/components/VsVerticalPercentageBar/Index.vue'
import VsHeatmap from '@/modules/campaigns/components/VsHeatmap/Index.vue'
import html2pdf from 'html2pdf.js'
import VsChart from '@/components/VsChart/Index.vue'
import VsTable from '@/components/VsTable/Index.vue'
import { UserModule } from '@/store/modules/user'
import { getFormConversions, getFormConversionsTrend } from '@/api/leadplus/statistics'
import { LeadplusForm } from '@/api/leadplus/types'
import moment from 'moment'

@Component({
    name: 'VsFormStatisticsFullAcquisition',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsVerticalPercentageBar,
        VsChart,
        VsLoader,
        VsSectionHeader,
        VsTable,
        VsHeatmap,
    },
})
export default class extends Vue {
    @Prop({ default: '', required: true }) form!: LeadplusForm
    private open = false
    private loading = false
    private conversionsTime: 'last24Hours' | 'last7Days' | 'last30Days' = 'last24Hours'
    conversions: any[] = []
    lastConversion = null
    lastDates: moment.Moment[] = []
    $refs: any

    get conversionTax () {
        if (!this.form || !this.form.total_visitors) return 0
        if (this.form.total_subscribers > this.form.total_visitors) return 100
        return (this.form.total_subscribers / this.form.total_visitors) * 100
    }

    private chartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${payload[0].axisValue}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: false,
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
        ],
        series: [
            {
                name: 'Conversioni',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
        ],
    }

    get formatDateFromTimeSelected () {
        switch (this.conversionsTime) {
            case 'last24Hours': return 'HH:mm'
            default: return 'DD MMM YYYY'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    public async openModal () {
        this.open = true
        this.loading = true
        await this.getConversions()
        await this.getLastConversion()
        this.loading = false
    }

    @Watch('conversionsTime', { immediate: false })
    async getConversions () {
        const params: any = {
            periodType: 'hours',
            periodValue: 24,
        }

        if (this.conversionsTime === 'last7Days') {
            params.periodType = 'days'
            params.periodValue = 7
        }

        if (this.conversionsTime === 'last30Days') {
            params.periodType = 'days'
            params.periodValue = 30
        }

        try {
            const resp = await getFormConversionsTrend(this.form._id, params)
            this.conversions = resp.data
            this.chartOptions.xAxis.data = resp.data.map((el: any) => moment(el.date).format(this.formatDateFromTimeSelected))
            this.chartOptions.series[0].data = resp.data.map((el: any) => el.count)
        } catch (e) {
            console.log(e)
        }
    }

    public closeModal () {
        this.open = false
    }

    async downloadPdf () {
        this.loading = true
        this.$refs.printContent.$el.style.width = '800px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `${this.form?.name}_engagement.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                hotfixes: ['px_scaling'],
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }

    async getLastConversion () {
        try {
            const resp = await getFormConversions(this.form._id, {
                limit: 1,
                sort: `-createdAt`,
            })

            if (resp.data.docs.length > 0) {
                this.lastConversion = resp.data.docs[0]
            }
        } catch (e) {
            console.log(e)
        }
    }
}
