import { serviceLeadplus } from '@/utils/request'

export const getFormImpressions = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/impressions`, {
        params,
        // signal,
    })

export const getFormImpressionsTrend = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/impressions/trend`, {
        params,
        // signal,
    })

export const getFormConversionsTrend = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/conversions/trend`, {
        params,
        // signal,
    })

export const getFormErrorsTrend = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/errors/trend`, {
        params,
        // signal,
    })

export const getFormConversions = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/conversions`, {
        params,
        // signal,
    })

export const getFormErrors = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/statistics/${formId}/errors`, {
        params,
        // signal,
    })
