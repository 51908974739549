






























































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import { deleteFormById, getFormById } from '@/api/leadplus/forms'
import { getList } from '@/api/consoleApi/recipients'
import DuplicateFormModal from '@/modules/leadplus/components/DuplicateFormModal/Index.vue'
import { AppModule } from '@/store/modules/app'
import type { LeadplusForm } from '@/api/leadplus/types'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import FormNameModal from '@/modules/leadplus/components/FormNameModal/Index.vue'
import { get } from 'lodash'
import html2pdf from 'html2pdf.js'
import { UserModule } from '@/store/modules/user'
import VsFormStatisticsFullEngagement from '@/modules/leadplus/components/VsFormStatisticsFullEngagement/Index.vue'
import VsFormStatisticsFullAcquisition from '@/modules/leadplus/components/VsFormStatisticsFullAcquisition/Index.vue'
import VsFormStatisticsFullQuality from '@/modules/leadplus/components/VsFormStatisticsFullQuality/Index.vue'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import {
    getFormImpressions,
    getFormConversions,
    getFormErrors,
} from '@/api/leadplus/statistics'

@Component({
    name: 'VsLeadplusFormStatistics',
    components: {
        VsContainer,
        VsSectionHeader,
        VsLoader,
        VsConfirm,
        VsDropdownButton,
        VsHintCard,
        VsInlineErrorsCheck,
        DuplicateFormModal,
        FormNameModal,
        VsFormStatisticsFullEngagement,
        VsFormStatisticsFullAcquisition,
        VsFormStatisticsFullQuality,
        VsSeparator,
        VsTabsHeading,
    },
})
export default class extends Vue {
    loading = false
    form: LeadplusForm | null = null
    list: any = null
    lastImpression = null
    lastError = null
    lastConversion = null
    $refs!: any

    get formId () {
        return this.$route.params.formId
    }

    get listName () {
        return this.list?.name || 'lista non trovata'
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    get formType () {
        switch (this.form?.theme?.type) {
            case 'popover':
                return 'Pop up'
            case 'embedded':
                return 'Embedded form'
            case 'topbar':
                return 'Top bar'
            case 'scrollbox':
                return 'Scroll box'
            default:
                return this.form?.theme?.type
        }
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get conversionTax () {
        if (!this.form || !this.form.total_visitors) return 0
        if (this.form.total_subscribers > this.form.total_visitors) return 100
        return (this.form.total_subscribers / this.form.total_visitors) * 100
    }

    get errorTax () {
        if (!this.form || !this.form.total_visitors) return 0
        if (this.form.total_errors > this.form.total_visitors) return 100
        return (this.form.total_errors / this.form.total_visitors) * 100
    }

    get tabs () {
        return [
            {
                label: 'Riepilogo',
                id: 'editForm',
                to: {
                    name: 'editForm',
                    params: {
                        formId: this.formId,
                    },
                },
            },
            {
                label: 'Statistiche',
                id: 'formStatistics',
                to: {
                    name: 'formStatistics',
                    params: {
                        formId: this.formId,
                    },
                },
            },
        ]
    }

    get activeTab () {
        return this.$route.name
    }

    @Watch('formId', { immediate: true })
    async getForm () {
        this.loading = true
        try {
            const resp = await getFormById(this.formId)
            this.form = resp.data
        } catch (e) {
            console.log(e)
            this.form = null
            const status = get(e, 'response.data.statusCode', 0)
            const message = get(e, 'response.data.message', [''])[0]
            if (status === 404 || (status === 400 && message === 'formId must be a mongodb id')) {
                this.$root.$vsToast({
                    heading: 'Form non trovato',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
                this.$router.replace({
                    name: 'leadplusForms',
                })
            }
        }
        this.loading = false
    }

    async downloadPdf () {
        const opt = {
            filename: `${this.form?.name}_overview.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                // foreignObjectRendering: true,
                // scale: 1,
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                // hotfixes: 'px_scaling',
            },
        }
        await html2pdf().from(this.$refs.basePrintContent.$el).set(opt).save()
    }

    async deleteForm () {
        try {
            await this.$refs.confirmFormDelete.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            await deleteFormById(this.formId)
            this.$router.replace({
                name: 'leadplusForms',
            })

            this.$root.$vsToast({
                heading: 'Form cancellato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del form',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async getList (listId: string) {
        try {
            const resp = await getList(listId)
            this.list = resp.data.data
        } catch (e) {
            this.list = null
            console.log(e)
        }
    }

    @Watch('formId', { immediate: true })
    async getLastImpression () {
        try {
            const resp = await getFormImpressions(this.formId, {
                limit: 1,
                sort: `-createdAt`,
            })
            if (resp.data.docs.length > 0) {
                this.lastImpression = resp.data.docs[0]
            }
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('formId', { immediate: true })
    async getLastConversion () {
        try {
            const resp = await getFormConversions(this.formId, {
                limit: 1,
                sort: `-createdAt`,
            })

            if (resp.data.docs.length > 0) {
                this.lastConversion = resp.data.docs[0]
            }
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('formId', { immediate: true })
    async getLastError () {
        try {
            const resp = await getFormErrors(this.formId, {
                limit: 1,
                sort: `-createdAt`,
            })

            if (resp.data.docs.length > 0) {
                this.lastError = resp.data.docs[0]
            }
        } catch (e) {
            console.log(e)
        }
    }

    formCreated (form: LeadplusForm) {
        this.$router.push({
            name: 'editForm',
            params: {
                formId: form._id,
            },
        })
    }
}
